import { Component } from 'react';
import Quiz from './Quiz/Quiz';

export default class App extends Component {

  constructor(props) {
    super(props);

  }


  render() {
    return (
      <Quiz />
    )
  }
}
